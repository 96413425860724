// Generated by Framer (24f4d74)

import * as React from "react";
import { motion, LayoutGroup } from "framer-motion";
import { useOnVariantChange, useActiveVariantCallback, Image, addFonts, withCSS, addPropertyControls, ControlType, cx, useVariantState, CycleVariantState } from "framer";

const cycleOrder = ["BCqREH6Iy", "ibjlnzPEO"];

const variantClassNames = {BCqREH6Iy: "framer-v-y99o1n", ibjlnzPEO: "framer-v-1apesf8"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {Burger: "BCqREH6Iy", X: "ibjlnzPEO"};

const transitions = {default: {type: "spring", ease: [0.44, 0, 0.56, 1], duration: 5, delay: 0, stiffness: 400, damping: 30, mass: 1}};

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

const useRandomID = () => {const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; tap?: any; color?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "BCqREH6Iy", tap: TBgHLr7MW, color: iWF8EWIN1 = "var(--token-0c3f76d2-28b9-4470-a1ca-46330b36daa2, rgb(255, 255, 255)) /* {\"name\":\"White\"} */", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const { variants, baseVariant, gestureVariant, classNames, transition, setVariant, setGestureState } = useVariantState({defaultVariant: "BCqREH6Iy", variant, transitions, variantClassNames, cycleOrder});
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant);

const onTapy99o1n = activeVariantCallback(async (...args) => {
if (TBgHLr7MW) {
const res = await TBgHLr7MW(...args);
if (res === false) return false;
}
setVariant("ibjlnzPEO")
})

const onTap1apesf8 = activeVariantCallback(async (...args) => {
if (TBgHLr7MW) {
const res = await TBgHLr7MW(...args);
if (res === false) return false;
}
setVariant("BCqREH6Iy")
})

const defaultLayoutId = useRandomID();

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-yElq6", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-y99o1n", className)} style={{...style}} layoutId={"BCqREH6Iy"} transition={transition} layoutDependency={layoutDependency} background={null} data-highlight={true} data-framer-name={"Burger"} onTap={onTapy99o1n} ref={ref} {...addPropertyOverrides({ibjlnzPEO: {onTap: onTap1apesf8, "data-framer-name": "X", "data-highlight": true}}, baseVariant, gestureVariant)}><motion.div className={"framer-3u41mj"} style={{}} layoutId={"XXMtUE79I"} transition={transition} layoutDependency={layoutDependency} background={null}><motion.div className={"framer-lewpqu"} style={{backgroundColor: iWF8EWIN1, rotate: 0}} layoutId={"GML2BkMWN"} transition={transition} layoutDependency={layoutDependency} data-framer-name={"Bottom"} variants={{ibjlnzPEO: {rotate: -45}}} {...addPropertyOverrides({ibjlnzPEO: {transformTemplate: undefined}}, baseVariant, gestureVariant)}/><motion.div className={"framer-17lctcf"} style={{backgroundColor: iWF8EWIN1, rotate: 0, opacity: 1}} layoutId={"i_cv8RMpz"} transition={transition} layoutDependency={layoutDependency} data-framer-name={"Mid"} variants={{ibjlnzPEO: {rotate: 0, opacity: 0}}} {...addPropertyOverrides({ibjlnzPEO: {transformTemplate: undefined}}, baseVariant, gestureVariant)}/><motion.div className={"framer-30clnq"} style={{backgroundColor: iWF8EWIN1, rotate: 0}} layoutId={"IceoGvF4R"} transition={transition} layoutDependency={layoutDependency} data-framer-name={"Top"} variants={{ibjlnzPEO: {rotate: 45}}} {...addPropertyOverrides({ibjlnzPEO: {transformTemplate: undefined}}, baseVariant, gestureVariant)}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-yElq6 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-yElq6 * { box-sizing: border-box; }", ".framer-yElq6 .framer-y99o1n { position: relative; cursor: pointer; overflow: hidden; width: 32px; height: 32px; }", ".framer-yElq6 .framer-3u41mj { position: absolute; overflow: visible; width: 24px; height: 18px; left: calc(50.00000000000002% - 24px / 2); top: calc(50.00000000000002% - 18px / 2); flex: none; }", ".framer-yElq6 .framer-lewpqu { position: absolute; overflow: hidden; height: 2px; right: 0px; bottom: 0px; left: 0px; flex: none; }", ".framer-yElq6 .framer-17lctcf { position: absolute; overflow: hidden; height: 2px; right: 0px; left: 0px; top: calc(50.00000000000002% - 2px / 2); flex: none; }", ".framer-yElq6 .framer-30clnq { position: absolute; overflow: hidden; height: 2px; right: 0px; left: 0px; top: 0px; flex: none; }", ".framer-yElq6.framer-v-1apesf8 .framer-y99o1n { cursor: pointer; }", ".framer-yElq6.framer-v-1apesf8 .framer-lewpqu { width: auto; height: 2px; right: 0px; bottom: auto; left: 0px; top: calc(50.00000000000002% - 2px / 2); flex: none; aspect-ratio: unset; }", ".framer-yElq6.framer-v-1apesf8 .framer-17lctcf { width: 2px; height: 2px; right: 0px; bottom: auto; left: auto; top: calc(50.00000000000002% - 2px / 2); flex: none; aspect-ratio: unset; }", ".framer-yElq6.framer-v-1apesf8 .framer-30clnq { width: 24px; height: 2px; right: 0px; bottom: auto; left: auto; top: calc(50.00000000000002% - 2px / 2); flex: none; aspect-ratio: unset; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"ibjlnzPEO":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"TBgHLr7MW":"tap","iWF8EWIN1":"color"}
 */
const FramerZTVKuTo3l: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerZTVKuTo3l;

FramerZTVKuTo3l.displayName = "Elements/Menu Icon";

FramerZTVKuTo3l.defaultProps = {width: 32, height: 32};

addPropertyControls(FramerZTVKuTo3l, {variant: {type: ControlType.Enum, title: "Variant", options: ["BCqREH6Iy", "ibjlnzPEO"], optionTitles: ["Burger", "X"]}, TBgHLr7MW: {type: ControlType.EventHandler, title: "Tap"}, iWF8EWIN1: {type: ControlType.Color, title: "Color", defaultValue: "var(--token-0c3f76d2-28b9-4470-a1ca-46330b36daa2, rgb(255, 255, 255)) /* {\"name\":\"White\"} */"}} as any);

addFonts(FramerZTVKuTo3l, []);